<template>
  <div class="instructors">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this category!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Are you sure you would like to delete this category?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Assign Categories</h1>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">
            <router-link :to="{ path: '/videos/categorize/add' }"
              ><button type="button" class="btn btn-info">
                Add
              </button></router-link
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(assignment, index) in categorize"
          v-bind:key="assignment.id"
        >
          <th scope="row">{{ assignment.category }}</th>
          <td>{{ assignment.title }}</td>
          <td>
            <button
              type="button"
              class="btn btn-danger"
              @click="
                confirmDeleteRecord(
                  $event,
                  assignment.category_id,
                  assignment.video_id,
                  index
                )
              "
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      categorize: [],
      deleteRecord: {
        index: 0,
        id: 0
      }
    };
  },

  methods: {
    saveDeleteRecord: function() {
      var $this = this;

      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        this.$http
          .delete(
            process.env.VUE_APP_ROOT_API +
              "/videos/categorize?videoId=" +
              this.deleteRecord.video_id +
              "&categoryId=" +
              this.deleteRecord.category_id,
            options
          )
          .then(response => {
            console.log(response);
            //$('#deleteRecordModal').modal('hide');
            document.querySelectorAll("[data-dismiss=modal]")[0].click();
            $this.categorize.splice($this.deleteRecord.index, 1);
          });
      }
    },

    confirmDeleteRecord: function(event, category_id, video_id, index) {
      this.deleteRecord = {
        category_id: category_id,
        video_id: video_id,
        index: index
      };

      $("#deleteRecordModal").modal({});
    }
  },

  created() {
    this.$http
      .get(process.env.VUE_APP_ROOT_API + "/videos/categorize")
      .then(response => {
        this.categorize = response.data;
      });
  }
};
</script>
<style></style>
